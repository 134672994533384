html {
  font-size: 62.5%;
}

body {
  background: linear-gradient(to right,rgb(209, 218, 251), rgb(215, 216, 255), rgb(229, 239, 255));
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media only screen and (min-width: 1000px) {
  
  html {
    font-size: 80%;
  }
  
  body {
    background-image: url(https://i.imgur.com/pMUOslz.png);
    color: #777575;
  }
}