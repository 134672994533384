.Item-description {
  background: white;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
  border-radius: 10px;
  margin-top: 30px;
  width: 100%;
  max-width: 400px;
}

.Item-description__image {
  width: 90%;
  margin: 5%;
  border-radius: 5px;
}

.Item-description__title {
  margin: 0;
  padding-left: 5%;
  font-size: 2.4rem;
}

.Item-description h3 {
  font-size: 1.8rem;
  padding: 5%;
  padding-top: 2%;
  padding-bottom: 0;
  margin: 0;
  text-align: justify;
}
.Item-description__description {
  padding: 5%;
  padding-bottom: 10%;
  margin: 0;
  text-align: justify;
  font-size: 1.6rem;
  white-space: pre-wrap;
}

@media only screen and (min-width: 768px) {
  
  .Item-description {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1000px) {

  .Item-description {
    background: #f3f1f1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Item-description__image {
    width: 30%;
    margin: 10px;
    margin-top: 20px;
  }

  .Item-description__title {
    width: 100%;
  }

  .Item-description h1 {
    font-size: 2rem;
  }
  .Item-description h3 {
    width: 100%;
  }

  .Item-description__description {
    padding-top: 2%;
  }
}