.Content__profile {
  display: flex;
  height: 41vw;
  padding-top: 3%;
}

.Content__profile-photo {
  border-radius: 50%;
  height: 27vw;
  object-fit: scale-down;
  z-index: 2;
  margin-left: 7%;
  margin-top: 9%;
  margin-bottom: 9%;
}

.Content__profile-title {
  background: linear-gradient(to right, rgb(37, 133, 252), rgb(42, 67, 247), rgb(61, 49, 240));;
  width: 58%;
  height: 35vw;
  position: absolute;
  margin-top: 5vw;
  margin-left: 20%;
  z-index: 1;
  padding-left: 17%;
  border-radius: 20px;
}

.Content__profile-title h1 {
  font-size: 2rem;
  padding: 0;
  padding-top: 20%;
  padding-bottom: 2%;
  margin: 0;
  color: white;
}

.Content__profile-title h2 {
  font-size: 1.8rem;
  padding: 0;
  margin: 0;
  color: white;
}

@media only screen and (min-width: 768px) {

  .Content__profile h1 {
    font-size: 3.5rem;
  }
  .Content__profile h2 {
    font-size: 2.8rem;
  }
}
@media only screen and (min-width: 1000px) {

  .Content {
    display: flex;
    justify-content: center;
  }
  .Content__profile {
    background: #fff;
    border: solid 0.1px rgb(197, 197, 197);
    margin-top: 150px;
    width: 60%;
    max-width: 1400px;
    height: fit-content;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: space-around;
  }

  .Content__profile-photo {
    margin: 0;
    padding: 0;
    border-radius: 0;
    width: 40%;
    height: auto;
  }

  .Content__profile-title {
    margin: 0;
    padding: 0;
    position: relative;
    width: 50%;
    height: fit-content;
    padding-top: 35px;
    background: none;
    border-bottom: solid #8a8888;
    border-radius: 0;
    text-align: center;
  }

  .Content__profile-title h1 {
    padding-top: 0;
    padding-bottom: 0;
    color:#777575;
    font-size: 2.5rem;
  }

  .Content__profile-title h2 {
    padding-top: 0;
    color:#8a8888;
    font-size: 2rem;
  }
  
}

@media only screen and (min-width: 2400px) {

  .Content__profile-title h1 {
    font-size: 3.5rem;
  }

  .Content__profile-title h2 {
    font-size: 2.8rem;
  }

}