.Container {
  background: linear-gradient(to right,rgb(209, 218, 251), rgb(215, 216, 255), rgb(229, 239, 255));
  box-shadow: rgb(0 0 0 / 25%) 0px -2px 4px;
  width: 100%;
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  height: 6rem;
  margin-left: 0;
  transition: 0.5s;
}

.Container--off {
  margin-bottom: -20%;
}


.Menu {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 82%;
  height: 6rem;
}

.Menu a {
  text-decoration: none;
}

.Menu p {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1rem;
  color: rgb(61, 49, 240);
  font-weight: bold;
}


.Menu__button--option {
  font-size: 2.4rem;
  color: white;
  background: rgb(61, 49, 240);
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  padding: 0;
  margin: 0;
  outline: none;
}

.Menu__button--option img {
  width: 2rem;
  height: 2rem;
}

.Menu__container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.Menu__button {
  font-size: 3.2rem;
  color: white;
  background: rgb(61, 49, 240);
  width: 6rem;
  height: 6rem;
  border: 0;
  border-radius: 50% 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  padding: 0;
  margin: 0;
  margin-left: 85%;
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  outline: none;
}


.Menu__button--img-close {
  width: 50%;
  padding: 0;
  margin: 0;
  transition: 900ms;
}

.Menu__button--img-open {
  width: 50%;
  padding: 0;
  margin: 0;
  transform: rotate(225deg);
  transition: 900ms;
}


@media only screen and (min-width: 1000px) {

  .Container--off {
    background: #fff;
    margin: 0;
    margin-left: 10%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    height: 500px;
    width: 9%;
    border-radius: 10px;
    top:25%;
  }

  .Menu {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    width: 70px;
  }

  .Menu a {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Menu p {
    font-size: 1.4rem;
  }

  .Menu__container {
    display: none;
  }

}

@media only screen and (min-width: 1440px) {

  .Menu__button {
    width: 6%;
  }
}

@media only screen and (min-width: 1900px) {

  .Container--off {
    margin-left: 12%;
    width: 7%;
  }

}

@media only screen and (min-width: 2400px) {

  .Container--off {
    margin-left: 13%;
  }

}