.Profile__details {
  padding: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Profile__details-content {
  padding: 4%;
  text-align: justify;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
  border-radius: 3px;
}

.Profile__details-content p {
  font-size: 1.6rem;
}

.Profile__details-netWork {
  display: flex;
  justify-content: space-around;
  margin-top: 23px;
}

.Profile__button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #3a66f5;
  font-size: 1.6rem;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  text-decoration: none;
  font: outline;
  color: white;
  font-weight: bold;
  border: none;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 6rem;
  outline: none;
}

.Profile__button:hover {
  background: #1d3a99;;
}

@media only screen and (min-width: 1000px) {
  .Profile-content {
    display: flex;
    justify-content: center;
  }

  .Profile__details {
    background: #fff;
    border: solid 0.1px rgb(197, 197, 197);
    margin:0;
    max-width: 1400px;
    width: 60%;
    padding: 10px;
    margin-top: 5px;
    box-sizing: border-box;   
  
  }

  .Profile__details-content { 
    box-shadow:none; 
  }

  .Profile__details-netWork {
    width: 100%;
    justify-content: center;
  }

  .Profile__button {
    margin-top: 50px;
    width: 200px;
  }
}