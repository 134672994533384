.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(240, 240, 240);
  width: 100%;
  height: 100vh;
}

.not-found h1 {
  font-size: 3rem;
}