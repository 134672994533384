.Social-netWork {
  display: flex;
  text-align: center;
  align-items: center;
}

.Social-netWork a {

}

.Social-netWork img {
  width: 50%;
}