.Projects-item {
  background: white;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
  border-radius: 10px;
  margin-top: 30px;
  max-width: 400px;
}

.Projects-item__image {
  width: 90%;
  margin: 5%;
  border-radius: 5px;
  border: solid #eae7e7 0.1px;
}

.Projects-item__title {
  margin: 0;
  padding-left: 5%;
}

.Projects-item__description {
  padding: 5%;
  margin: 0;
  text-align: justify;
  font-size: 1.6rem;
}

.Projects-item__link {
  width: 90%;
  margin-left: 5%;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.Projects-item__link-item {
  background: linear-gradient(to right, rgb(37, 133, 252), rgb(42, 67, 247), rgb(61, 49, 240));
  width: 49%;
  height: 2.8rem;
  display: grid;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 15px;
  color: white;
  text-decoration: none;
  font-size: 1.6rem;
}

@media only screen and (min-width: 768px) {

  .Projects-item {
    max-width: 330px;
  }
}

@media only screen and (min-width: 1000px) {

  .Projects-item {
    background: #f3f1f1;
    max-width: 500px;
  }
}