.Item {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Item__header {
  background: linear-gradient(to right,rgb(61, 49, 240), rgb(42, 67, 247), rgb(37, 133, 252));
  background: rgb(61, 49, 240);
  position: relative;
  margin-top: 10%;
  margin-bottom: 5%;
  padding: 1rem;
  margin-left: 5%;
  width: 95%;
  border-radius: 10px 0 0 10px;
}

.Item__header-title {
  font-size: 2.8rem;
  margin: 0;
  padding: 0;
  color: white;
}

.Item__content {
  width: 90%;
  padding-bottom: 6.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  
}

@media only screen and (min-width: 768px) {

  .Item__header {
    width: 90%;
    border-radius: 10px;
    margin-right: 5%;
  }

  .Item__content {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
  }
}

@media only screen and (min-width: 1000px) {

  .Item__header {
    margin: 0;
    background: #fff;
    margin-top: 150px;
    margin-bottom: 5px;
    width: 60%;
    height: 200px;
    max-width: 1400px;
    box-sizing: border-box;
    border-radius:  0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }

  .Item__header-title {
    text-align: center;
    color: #777575;
    margin-top: -20px;
    padding-bottom: 10px;
    border-bottom: solid gray;
    width: 60%;
    height: fit-content;
  }

  .Item__content {
    background: #fff;
    width: 60%;
    max-width: 1400px;
    padding: 50px;
    box-sizing: border-box;
    justify-content: space-around;
  }
}